<!-- 门禁模块--闸机详情页--操作状态表格 -->
<template>
  <div id="detailstabel">
    <table>
      <tr class="tabletitle">
        <td>{{ $t("details.a13") }}</td>
        <td>{{ $t("details.a14") }}</td>
        <td>{{ $t("details.a15") }}</td>
        <td>{{ $t("details.a13") }}</td>
        <td>{{ $t("details.a14") }}</td>
        <td>{{ $t("details.a15") }}</td>
      </tr>
      <tr class="tablecontent top buttom">
        <td>{{ $t("details.a38") }}</td>
        <td>
          <img src="../../../../public/images/icon/532.png" />
        </td>
        <td></td>
        <td>{{ $t("details.a39") }}</td>
        <td>
          <img src="../../../../public/images/icon/532.png" />
        </td>
        <td></td>
      </tr>
      <tr class="tablecontent top buttom">
        <td>{{ $t("details.a40") }}</td>
        <td>
          <img src="../../../../public/images/icon/532.png" />
        </td>
        <td></td>
        <td>{{ $t("details.a41") }}</td>
        <td><img src="../../../../public/images/icon/532.png" /></td>
        <td></td>
      </tr>
      <tr class="tablecontent top buttom">
        <td>{{ $t("details.a42") }}</td>
        <td>
          <img src="../../../../public/images/icon/532.png" />
        </td>
        <td></td>
        <td>{{ $t("details.a43") }}</td>
        <td><img src="../../../../public/images/icon/532.png" /></td>
        <td></td>
      </tr>
      <tr class="tablecontent top buttom">
        <td>{{ $t("details.a44") }}</td>
        <td>
          <img src="../../../../public/images/icon/532.png" />
        </td>
        <td></td>
        <td>{{ $t("details.a45") }}</td>
        <td><img src="../../../../public/images/icon/532.png" /></td>
        <td></td>
      </tr>
      <tr class="tablecontent top buttom">
        <td>{{ $t("details.a46") }}</td>
        <td>
          <img src="../../../../public/images/icon/532.png" />
        </td>
        <td></td>
        <td>{{ $t("details.a47") }}</td>
        <td><img src="../../../../public/images/icon/532.png" /></td>
        <td></td>
      </tr>
      <tr class="tablecontent top buttom">
        <td>{{ $t("details.a48") }}</td>
        <td>
          <img src="../../../../public/images/icon/532.png" />
        </td>
        <td></td>
        <td>{{ $t("details.a49") }}</td>
        <td><img src="../../../../public/images/icon/532.png" /></td>
        <td></td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
  },
};
</script>
<style scoped>
#detailstabel {
  width: 100%;
  margin-bottom: 40px;
  white-space: nowrap;
  overflow: hidden;
}
table {
  width: 100%;
}
.tabletitle td {
  color: #3e3a39;
  font-size: 14px;
  font-weight: 500;
  padding: 16px;
}
.tablecontent td {
  padding: 10px 16px;
  font-size: 14px;
}
.top {
  border-top: 1px solid #dedfdf;
}
.buttom {
  border-bottom: 1px solid #dedfdf;
}
.table-button {
  width: 120px;
}
img {
  width: 36px;
  margin-right: 15px;
}
</style>