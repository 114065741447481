<!--门禁模块--闸机详情页-->
<template>
  <div
    id="gateMachineDetails"
    v-loading="spinning"
    :element-loading-text="$t('details.a1')"
  >
  <!-- 头部 -->
    <div class="header">
      <div class="header-header">
        <span>{{ data.name }}</span>
      </div>
      <a-icon
        type="close"
        :style="{ fontSize: '20px', color: '#7682CE' }"
        @click="goDack"
      />
    </div>
    <div class="main">
      <!-- 基本信息 -->
      <my-info :data="data.baseInfo" @updateName="updateName" />
      <!-- 操作状态/故障·报警标题 -->
      <my-title :title="title" />
      <my-table :data="data.flapperItemInfo" />
      <div class="main-layout">
        <div class="main-layout-left">
          <!-- 设备详情信息 -->
          <my-devicedetail
            :data="data.doorDetail"
            :type="data.type"
            :id="data.id"
          />
        </div>
        <div class="main-layout-right">
          <!-- 时间表恢复 -->
          <my-schedule
            :data="data.scheduleRecover"
            :type="data.type"
            :id="data.id"
          />
        </div>
      </div>
      <!-- 时间表手动恢复 -->
      <my-recovery :type="data.type" :id="data.id" />
      <!-- 当次日时间表标题 -->
      <my-templatetitle :name="data.name" />
      <!-- 当次日时间表 -->
     <my-dailySchedule :data="data"/>
    </div>
  </div>
</template>

<script>
import info from "./components/baseInfo"
import tabletitle from "../../components/Title/tabletitle"
import gateMachineTable from "./components/gateMachineTable"
import templateTitle from "./components/templateTitle"
import doorDeviceDetail from "./components/doorDeviceDetail"
import timetableResumption from "./components/timetableResumption"
import manualRecovery from "./components/manualRecovery"
import { getGateMachineDetails } from "../../api/door"
import dailySchedule from './components/dailySchedule.vue'
import moment from "moment"

export default {
  components: {
    "my-info": info,
    "my-title": tabletitle,
    "my-table": gateMachineTable,
    "my-templatetitle": templateTitle,
    "my-devicedetail": doorDeviceDetail,
    "my-schedule": timetableResumption,
    "my-recovery": manualRecovery,
    'my-dailySchedule':dailySchedule
  },
  data() {
    return {
      spinning: false,
      bloading: false,
      data: {},
      title: this.$t("details.a101"),
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    moment,
    // 页面回退方法
    goDack() {
      this.$router.go(-1);
    },
    // 获取闸机详情页面数据的方法
    getData() {
      this.spinning = true;
      let flapperId = this.$route.query.id;
      getGateMachineDetails({ flapperId })
        .then((res) => {
          this.spinning = false;
          // console.log("闸机数据", res.data);
          let { data } = res;
          this.data = data;
          for (var i in data.scheduleRecover) {
            if (typeof data.scheduleRecover[i] == "string") {
              data.scheduleRecover[i] = moment(
                data.scheduleRecover[i],
                "HH:mm"
              );
            }
          }
        })
        .catch((err) => {
          this.spinning = false;
          console.log("闸机数据获取失败");
        });
    },
    // 基本信息名称修改成功后同时修改父组件中的名称
    updateName(data) {
      this.data.name = data;
    },
  }
};
</script>

<style scoped>
#gateMachineDetails {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px #bdbcbc;
  background: #ffffff;
  overflow: hidden;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  padding: 20px 20px 20px 20px;
  border-bottom: 1px solid #cccdcd;
  white-space: nowrap;
  overflow: hidden;
}
.header-header {
  display: flex;
  align-items: center;
}
.header-header span {
  margin-right: 30px;
}
.switch {
  display: flex;
  align-items: center;
}
.main {
  width: 100%;
  height: calc(100% - 68px);
  padding: 20px 20px 20px 20px;
  overflow: auto;
}
.main-layout {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.main-layout-left {
  width: 49%;
}
.main-layout-right {
  width: 49%;
}
.Set-timetable {
  display: flex;
  justify-content: center;
  border: 1px solid #e0e1e2;
  border-top: none;
  padding: 10px 20px;
  margin-bottom: 20px;
}
.Set-timetable button {
  width: 120px;
}
</style>