var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"detailstabel"}},[_c('table',[_c('tr',{staticClass:"tabletitle"},[_c('td',[_vm._v(_vm._s(_vm.$t("details.a13")))]),_c('td',[_vm._v(_vm._s(_vm.$t("details.a14")))]),_c('td',[_vm._v(_vm._s(_vm.$t("details.a15")))]),_c('td',[_vm._v(_vm._s(_vm.$t("details.a13")))]),_c('td',[_vm._v(_vm._s(_vm.$t("details.a14")))]),_c('td',[_vm._v(_vm._s(_vm.$t("details.a15")))])]),_c('tr',{staticClass:"tablecontent top buttom"},[_c('td',[_vm._v(_vm._s(_vm.$t("details.a38")))]),_vm._m(0),_c('td'),_c('td',[_vm._v(_vm._s(_vm.$t("details.a39")))]),_vm._m(1),_c('td')]),_c('tr',{staticClass:"tablecontent top buttom"},[_c('td',[_vm._v(_vm._s(_vm.$t("details.a40")))]),_vm._m(2),_c('td'),_c('td',[_vm._v(_vm._s(_vm.$t("details.a41")))]),_vm._m(3),_c('td')]),_c('tr',{staticClass:"tablecontent top buttom"},[_c('td',[_vm._v(_vm._s(_vm.$t("details.a42")))]),_vm._m(4),_c('td'),_c('td',[_vm._v(_vm._s(_vm.$t("details.a43")))]),_vm._m(5),_c('td')]),_c('tr',{staticClass:"tablecontent top buttom"},[_c('td',[_vm._v(_vm._s(_vm.$t("details.a44")))]),_vm._m(6),_c('td'),_c('td',[_vm._v(_vm._s(_vm.$t("details.a45")))]),_vm._m(7),_c('td')]),_c('tr',{staticClass:"tablecontent top buttom"},[_c('td',[_vm._v(_vm._s(_vm.$t("details.a46")))]),_vm._m(8),_c('td'),_c('td',[_vm._v(_vm._s(_vm.$t("details.a47")))]),_vm._m(9),_c('td')]),_c('tr',{staticClass:"tablecontent top buttom"},[_c('td',[_vm._v(_vm._s(_vm.$t("details.a48")))]),_vm._m(10),_c('td'),_c('td',[_vm._v(_vm._s(_vm.$t("details.a49")))]),_vm._m(11),_c('td')])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('img',{attrs:{"src":require("../../../../public/images/icon/532.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('img',{attrs:{"src":require("../../../../public/images/icon/532.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('img',{attrs:{"src":require("../../../../public/images/icon/532.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('img',{attrs:{"src":require("../../../../public/images/icon/532.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('img',{attrs:{"src":require("../../../../public/images/icon/532.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('img',{attrs:{"src":require("../../../../public/images/icon/532.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('img',{attrs:{"src":require("../../../../public/images/icon/532.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('img',{attrs:{"src":require("../../../../public/images/icon/532.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('img',{attrs:{"src":require("../../../../public/images/icon/532.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('img',{attrs:{"src":require("../../../../public/images/icon/532.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('img',{attrs:{"src":require("../../../../public/images/icon/532.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('img',{attrs:{"src":require("../../../../public/images/icon/532.png")}})])
}]

export { render, staticRenderFns }